import { Injectable } from '@angular/core';
import {
  GetUserPreferenceQuery,
  UpsertUserPreferenceQuery,
  UpsertUserPreferenceRqst,
  UserPreferenceApiService,
} from '@xpo-ltl/sdk-userpreference';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserPreferencesService {
  constructor(public userPreferenceApiService: UserPreferenceApiService) {}

  getPreferencesFor<T>(componentName: string, dataOptions = { loadingOverlayEnabled: false }): Observable<T> {
    const getPreferencesQuery = new GetUserPreferenceQuery();
    getPreferencesQuery.uiComponentName = componentName;
    return this.userPreferenceApiService.getUserPreference(getPreferencesQuery, dataOptions).pipe(
      map((response: any) => {
        try {
          const rawPreferences: string = response?.preferences;
          return rawPreferences ? (JSON.parse(rawPreferences) as T) : undefined;
        } catch (error) {
          console.error(`Error parsing the release user preferences for ${componentName}`, error);
        }
      })
    );
  }

  updatePreferencesFor<T>(componentName: string, preferences: T): Observable<void> {
    const upsertPreferencesRequest = new UpsertUserPreferenceRqst();
    upsertPreferencesRequest.uiComponentName = componentName;
    upsertPreferencesRequest.preferences = JSON.stringify(preferences);
    const upsertPreferencesQuery = new UpsertUserPreferenceQuery();
    return this.userPreferenceApiService.upsertUserPreference(upsertPreferencesRequest, upsertPreferencesQuery);
  }
}

import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ConstantsService {
  // Requests headers
  InterceptorSkipHeader = 'XPO-Skip-Interceptor';
  // Pricing code categories
  AGREEMENT_MANAGEMENT = 'AgreementManagement';
  // Pricing code sub-categories
  SECURITY = 'Security';
  // Formats
  DATE_FORMAT_FOR_REQUESTS = 'YYYY-MM-DD';
  DATE_FORMAT_FOR_DISPLAY = 'MM/DD/YYYY';
  DATE_FORMAT_FOR_SERVICE = 'DD/MM/YYYY';
  SHORT_YES = 'Y';
  SHORT_NO = 'N';
  SHORT_PENDING = 'P';
  // Export CSV
  EMPTY_VALUE = '';
  STORAGE = 'Storage';
  DETENTION = 'Detention';
  STORAGE_CSV = 'EAM_Storage.csv';
  STORAGE_FILTERED_CSV = 'EAM_Storage_Filtered.csv';
  DETENTION_CSV = 'EAM_Detention.csv';
  DETENTION_FILTERED_CSV = 'EAM_Detention_Filtered.csv';
  DOWNLOAD_ERROR_MESSAGE = 'Export CSV failed. Reduce the number of records or try again later.';
  // Ruleset
  PROGRAM_TYPE_CD = 'programTypeCd';
  TRAFFIC_TYPE_CD = 'trafficTypeCd';
  RULESET_VARIABLE = 'variable';
  RULESET_REGULAR = 'regular';
  RULESET_MATRIX = 'matrix';
  RULESET_COMMODITY = 'commodity';
  // Storage tab
  BLANK = 'Blank';
  MAX_REQUEST_RECORDS = 5000;
  MAX_PAGE_SIZE = 10000;
  LOST_POTENTIAL = 'Lost Potential';
  NOTICE_PROVIDED_CD = 'noticeProvidedCd';
  BILLED_STATUS_CD = 'billedStatusCd';
  ACCESSORIAL_VALID_CD = 'accessorialValidCd';
  REASON_DESCRIPTION = 'reasonDescription';
  UNIT_QUANTITY = 'unitQuantity';
  ACCESORIAL_UPDATE_FAILED_ERROR = 'Accessorial update failed';
  // Status
  ACTIVE = 'Active';
  matches = 'Matches';
  lessThanOrEqual = 'LessThanOrEqual';
  greaterThanOrEqual = 'GreaterThanOrEqual';
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ApplicationUtilsService } from '../../../services/application-utils/application-utils.service';
import { ConfigManagerService } from '@xpo-ltl/config-manager';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  @Input() buildVersion: string;

  @Output() releaseNotes = new EventEmitter<void>();

  email: string = 'LTLITPricingTeam@xpo.com';
  subject: string = 'Feedback - Edge Agreement Management';

  constructor(
    public configManagerService: ConfigManagerService,
    public applicationUtilsService: ApplicationUtilsService
  ) {}

  openHelpLink(): void {
    window.open(this.getHelpLink());
  }

  getHelpLink(): string {
    return this.applicationUtilsService.helpLink;
  }

  getMailLink(): string {
    return `mailto:${this.email}?subject=${this.subject}`;
  }
}

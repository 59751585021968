import { Injectable } from '@angular/core';
import { User } from '@xpo-ltl/sdk-common';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  userSource = new BehaviorSubject<User>(undefined);
  user$ = this.userSource.asObservable();

  get User(): User {
    return this.userSource.value;
  }

  set User(value: User) {
    this.userSource.next(value);
  }

  constructor() {}

  getUser(): Observable<User> {
    if (this.userSource.value) {
      return of(this.User);
    } else {
      return this.user$;
    }
  }
}

import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { XpoColors } from '../../../enums/xpo-colors';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { DatePipe } from '@angular/common';

export enum StatusIndicatorType {
  DateStatus,
  RulesetStatus,
}

export interface StatusIndicatorCellRendererParams extends ICellRendererParams {
  isLabel: boolean;
  statusType: StatusIndicatorType;
}

@Component({
  selector: 'app-status-cell-renderer',
  templateUrl: './status-cell-renderer.component.html',
  styleUrls: ['./status-cell-renderer.component.scss'],
})
export class StatusCellRendererComponent implements ICellRendererAngularComp {
  isLabel: boolean; // default - false
  statusColor: string = XpoColors.XPO_TRANSPARENT; // default - transparent
  tooltipText: string = null;
  statusText: string = null;
  params: StatusIndicatorCellRendererParams;

  constructor(private datePipe: DatePipe) {}

  agInit(params: StatusIndicatorCellRendererParams): void {
    this.params = params;

    switch (this.params.statusType) {
      case StatusIndicatorType.DateStatus:
        this.isLabel = params.isLabel;
        this.statusColor = this.isDueWithinDaysColor(params.value)[0];
        this.tooltipText = this.isDueWithinDaysColor(params.value)[1];
        this.statusText = this.datePipe.transform(params.value, 'MM/dd/YYYY');
        break;
      case StatusIndicatorType.RulesetStatus:
        break;
      default:
        break;
    }
  }

  isDueWithinDaysColor(effectiveDate: string) {
    const date = new Date(effectiveDate);
    const currentDate = new Date();

    if (date < currentDate) {
      return [XpoColors.XPO_RED_200, 'Overdue']; // Overdue
    } else if (this.isDueWithinDays(date, 5)) {
      return [XpoColors.XPO_YELLOW_350, 'Due within the next 5 days']; // Due within < 5 days
    } else {
      return [XpoColors.XPO_GREEN_450, '']; // Due within > 5 days
    }
  }

  isDueWithinDays(date: Date, days: number, checkPast: boolean = true): boolean {
    const currentDate = new Date();

    const timeDiff = date.getTime() - currentDate.getTime();
    const dayDiff = timeDiff / (1000 * 60 * 60 * 24);
    return checkPast ? dayDiff <= days : dayDiff > days;
  }

  refresh(): boolean {
    return false;
  }
}

import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appReplaceComma]'
})
export class ReplaceCommaDirective {
  constructor(
    private el: ElementRef,
    private ngControl: NgControl
    ) {
  }

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    const element = this.el.nativeElement as HTMLInputElement;
    this.replaceComma(event, element)
  }

  replaceComma(value: KeyboardEvent, element: HTMLInputElement) {
    return this.ngControl.control?.setValue(element.value.replace(/,/g,'.'), {emitEvent: false})
  }

}

<button
  *ngIf="visible"
  style="width: 30px; height: 30px"
  mat-icon-button
  [disabled]="disabled"
  (click)="onClick($event)"
  [matTooltip]="params.tooltip"
  matTooltipPosition="above"
>
  <xpo-icon [iconName]="iconName"></xpo-icon>
</button>

import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpOptions } from '@xpo-ltl/data-api';
import { Code } from '@xpo-ltl/sdk-common';
import {
  CreatePricingCodeRqst,
  GetPricingAnalystDetailsPath,
  GetPricingAnalystDetailsResp,
  ListPricingCodesByTypeQuery,
  ListPricingCodesByTypeResp,
  PricingAdminPersonnel,
  PricingApiService,
  UpdatePricingCodeRqst,
} from '@xpo-ltl/sdk-pricing';
import _ from 'lodash';
import { forkJoin, Observable, of } from 'rxjs/index';
import { catchError, map, retryWhen, shareReplay } from 'rxjs/internal/operators';
import { genericRetryStrategy } from '../../utils/rxjs-utils';
import { ConstantsService } from '../constants/constants.service';

interface RequestPricingCodesParams {
  categories: Array<string>;
  subCategories: Array<string>;
}
@Injectable({
  providedIn: 'root',
})
export class PricingService {
  private pricingCodesCache$: Observable<Code[]>;

  defaultCategories = [this.constants.AGREEMENT_MANAGEMENT];
  defaultSubCategories = [this.constants.SECURITY];

  constructor(private pricingApiService: PricingApiService, private constants: ConstantsService) {}

  requestPricingCodes(params: RequestPricingCodesParams = null): Observable<Code[]> {
    const queryParams = new ListPricingCodesByTypeQuery();
    queryParams.categoryCode = params ? params.categories : this.defaultCategories;
    queryParams.subCategoryCode = params ? params.subCategories : this.defaultSubCategories;
    return this.pricingApiService.listPricingCodesByType(queryParams, { loadingOverlayEnabled: false }).pipe(
      map((response: ListPricingCodesByTypeResp) => {
        return response.codeDetails;
      }),
      retryWhen(
        genericRetryStrategy({
          scalingDuration: 2000,
          excludedStatusCodes: [404],
        })
      ),
      catchError((error) => {
        console.error(error);
        return of([]);
      })
    );
  }

  updatePricingCodes(codes: Array<Code>) {
    const requests = [];
    codes.forEach((code: Code) => {
      const request = new UpdatePricingCodeRqst();
      request.pricingCodeId = code.pricingCodeId;
      request.codeDetails = code;
      requests.push(this.pricingApiService.updatePricingCode(request));
    });
    return forkJoin(requests);
  }

  createPricingCodes(codes: Array<Code>) {
    const requests = [];
    codes.forEach((code: Code) => {
      const request = new CreatePricingCodeRqst();
      request.codeDetails = code;
      requests.push(this.pricingApiService.createPricingCode(request));
    });
    return forkJoin(requests);
  }

  getPricingCodes() {
    if (!this.pricingCodesCache$) {
      this.pricingCodesCache$ = this.requestPricingCodes().pipe(shareReplay());
    }
    return this.pricingCodesCache$;
  }

  getAnalyst(employeeId: string): Observable<PricingAdminPersonnel> {
    const pathParams = new GetPricingAnalystDetailsPath();
    pathParams.pricingAnalystEmplId = employeeId;
    const dataOption = { toastOnError: false };
    const httpOptions: HttpOptions = {};
    // httpOptions.headers = new HttpHeaders().set(this.constants.InterceptorSkipHeader, '');
    return this.pricingApiService.getPricingAnalystDetails(pathParams, dataOption, httpOptions).pipe(
      map((response: GetPricingAnalystDetailsResp) => {
        return response.pricingAnalystDetails;
      })
    );
  }
}

import { Injectable } from '@angular/core';

export interface RulesetParams {
  agreementId: number,
  ruleSetSequenceNbr: number,
  effectiveDate?: string,
  error?: string
}

@Injectable({
  providedIn: 'root'
})
export class RulesetDetailService {
  rulesetParams: RulesetParams;
}

import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { XpoAuthenticationService } from '@xpo/ngx-auth';
import { take } from 'rxjs/operators';

@Injectable()
export class RfpStandaloneGuard implements CanActivate {
  constructor(private _router: Router, private xpoAuthenticationService: XpoAuthenticationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const user = this.xpoAuthenticationService.getUser();
    if (!user) {
      this.xpoAuthenticationService.startSignIn$(state.url).pipe(take(1)).subscribe();
    }
    return true;
  }

  canLoad(route: ActivatedRouteSnapshot, state): boolean {
    const realUrl = state.reduce((ac, value) => (ac += '/' + value));
    const user = this.xpoAuthenticationService.getUser();
    if (!user) {
      this.xpoAuthenticationService.startSignIn$(realUrl).pipe(take(1)).subscribe();
    }
    return true;
  }
}

import { Injectable } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import {
  AccessorialLiteral,
  BulkUpdateAccessorialLiteralsResp,
  BulkUpdateAccessorialLiteralsRqst,
  CreateAccessorialLiteralCorrectionRqst,
  CreateAccessorialLiteralRqst,
  GetAccessorialLiteralRateRqst,
  ListAccessorialLiteralHistsRqst,
  ListAccessorialLiteralsResp,
  ListAccessorialLiteralsRqst,
  ListShipmentAccessorialLiteralsQuery,
  PricingAgmtMgmtApiService,
} from '@xpo-ltl/sdk-pricingagmtmgmt';
import _ from 'lodash';
import moment from 'moment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigManagerProperties } from '../../enums/config-manager-properties.enum';
import { ConstantsService } from '../constants/constants.service';
import { PricingService } from '../pr/pricing.service';

enum Relation {
  Both = 'B',
  Consignee = 'C',
  Shipper = 'S',
  None = 'N',
}

@Injectable({
  providedIn: 'root',
})
export class StorageACService {
  pricingAgmtMgmtRatingAppUrl: string;
  pricingAgmtMgmtPricingDocumentSearchAppUrl: string;
  detailData = new BehaviorSubject<any>('');
  public accessorialData = this.detailData.asObservable();
  constructor(
    private pricingAgmtMgmtApiService: PricingAgmtMgmtApiService,
    private pricingService: PricingService,
    private constantsService: ConstantsService,
    private config: ConfigManagerService
  ) {
    this.pricingAgmtMgmtRatingAppUrl = this.config.getSetting(ConfigManagerProperties.pricingAgmtMgmtRatingAppUrl);
    this.pricingAgmtMgmtPricingDocumentSearchAppUrl = this.config.getSetting(ConfigManagerProperties.pricingAgmtMgmtPricingDocumentSearchAppUrl);
  }

  getStorageAccessorialCharges(filters: ListAccessorialLiteralsRqst): Observable<ListAccessorialLiteralsResp> {
    let request = new ListAccessorialLiteralsRqst();
    request = filters;
    request.accessorialLiteral.pickupDate = filters.accessorialLiteral.pickupDate
      ? moment(filters.accessorialLiteral.pickupDate).format(this.constantsService.DATE_FORMAT_FOR_REQUESTS)
      : null;
    request.accessorialLiteral.deliveryDate = filters.accessorialLiteral.deliveryDate
      ? moment(filters.accessorialLiteral.deliveryDate).format(this.constantsService.DATE_FORMAT_FOR_REQUESTS)
      : null;
    this.cleanNulls(request);
    return this.pricingAgmtMgmtApiService.listAccessorialLiterals(request, { loadingOverlayEnabled: false, toastErrorMessage: 'The API call failed' }).pipe(
      map((result: any) => {
        result.accessorialLiterals = result.accessorialLiterals.map((acc) => this.transformStorageAccessorialCharge(acc));
        return result;
      })
    );
  }

  private cleanNulls(request: ListAccessorialLiteralsRqst): void {
    Object.keys(request.accessorialLiteral).forEach(data => {
      if (!request.accessorialLiteral[data]) {
        request.accessorialLiteral[data] = undefined;
      }
    })
  }

  transformStorageAccessorialCharge(acc: AccessorialLiteral) {
    return {
      ...acc,
      _calculated_paMadCd: this.calculatePAMadCd(acc),
      _calculated_proNbr: this.transformProNumber(acc.proNbr),
    };
  }

  transformProNumber(value: string) {
    if (value.length === 11 && value[0] === '0' && value[4] === '0') {
      var tmp = value.split('');
      tmp.splice(0, 1);
      tmp.splice(3, 1);
      return tmp.join('');
    }
    return value;
  }

  calculatePAMadCd(accessorial: AccessorialLiteral) {
    let result = null;

    if (accessorial.ratedWithCd === Relation.Both) {
      result = accessorial.billToMadCd;
    } else if (accessorial.ratedWithCd === Relation.Consignee) {
      result = accessorial.consigneeMadCd;
    } else if (accessorial.ratedWithCd === Relation.Shipper) {
      result = accessorial.shipperMadCd;
    } else if (accessorial.ratedWithCd === Relation.None) {
      result = null;
    } else {
      result = null;
    }
    return result;
  }

  calculateAgreement(accessorial: AccessorialLiteral) {
    let result = null;

    if (accessorial.ratedWithCd === Relation.Both) {
      result = accessorial.billToAgreementId;
    } else if (accessorial.ratedWithCd === Relation.Consignee) {
      result = accessorial.consigneeAgreementId;
    } else if (accessorial.ratedWithCd === Relation.Shipper) {
      result = accessorial.shipperAgreementId;
    } else if (accessorial.ratedWithCd === Relation.None) {
      result = null;
    } else {
      result = null;
    }
    return result;
  }

  calculateRulesetNbr(accessorial: AccessorialLiteral) {
    let result = null;

    if (accessorial.ratedWithCd === Relation.Both) {
      result = accessorial.billToRulesetNbr;
    } else if (accessorial.ratedWithCd === Relation.Consignee) {
      result = accessorial.consigneeRulesetNbr;
    } else if (accessorial.ratedWithCd === Relation.Shipper) {
      result = accessorial.shipperRulesetNbr;
    } else if (accessorial.ratedWithCd === Relation.None) {
      result = null;
    } else {
      result = null;
    }
    return result;
  }

  cleanAccessorial(accessorial: any) {
    if (accessorial.hasOwnProperty('_calculated_paMadCd')) {
      delete accessorial['_calculated_paMadCd'];
    }
    if (accessorial.hasOwnProperty('_calculated_proNbr')) {
      delete accessorial['_calculated_proNbr'];
    }
    return accessorial;
  }

  updateAccessorialLiteral(accessorial: AccessorialLiteral): Observable<BulkUpdateAccessorialLiteralsResp> {
    const request = new BulkUpdateAccessorialLiteralsRqst();
    request.accessorialLiterals = [
      {
        ...this.cleanAccessorial(accessorial),
      },
    ];
    return this.pricingAgmtMgmtApiService.bulkUpdateAccessorialLiterals(request);
  }

  calculate(accessorialParam: AccessorialLiteral) {
    const request = new GetAccessorialLiteralRateRqst();
    request.accessorialLiteral = {
      ...this.cleanAccessorial(accessorialParam),
    };
    return this.pricingAgmtMgmtApiService.getAccessorialLiteralRate(request);
  }

  getCorrectionNote(accessorial: AccessorialLiteral) {
    let remarksText = null;
    const arrivalDate = moment(accessorial.arrivalDate).format(this.constantsService.DATE_FORMAT_FOR_DISPLAY);
    const deliveryDate = moment(accessorial.deliveryDate).format(this.constantsService.DATE_FORMAT_FOR_DISPLAY);
    const freeDays = accessorial.freeDaysCount || 0;
    const days = accessorial.unitQuantity || 0;
    remarksText = `STORAGE PROJECT- ADD DST- ${arrivalDate} THRU ${deliveryDate}`;
    if (freeDays) {
      remarksText += ` - ${freeDays} FREE`;
    }
    remarksText += ` = ${days} DAYS`;
    return remarksText;
  }

  correction(accessorialParam: AccessorialLiteral) {
    const accessorial = {
      ...this.cleanAccessorial(accessorialParam),
    };
    // TODO:       queueName: 'MANBOTHBIL',reasonCd: '4008',

    const request: CreateAccessorialLiteralCorrectionRqst = {
      accessorialLiteral: accessorial
    };
    return this.pricingAgmtMgmtApiService.createAccessorialLiteralCorrection(request);
  }

  async getRoleBasedEditableFields(role: string): Promise<Array<string>> {
    return new Promise(async (resolve, reject) => {
      try {
        const codes = await this.pricingService.getPricingCodes().toPromise();
        const editableFields = _.filter(codes, {
          category: this.constantsService.AGREEMENT_MANAGEMENT,
          subCategory: this.constantsService.SECURITY,
          code: role,
        }).map((code) => code.name);
        resolve(editableFields);
      } catch (error) {
        console.error(error);
        reject([]);
      }
    });
  }

  getNewAccessorial(proNumber: string, pickupDate: string): Observable<AccessorialLiteral> {
    const request = new ListShipmentAccessorialLiteralsQuery();
    request.proNbr = this.transformProNumber(proNumber);
    request.pickupDate = moment(pickupDate).format(this.constantsService.DATE_FORMAT_FOR_REQUESTS);
    request.includeMinimumInformationInd = true;
    return this.pricingAgmtMgmtApiService
      .listShipmentAccessorialLiterals(request)
      .pipe(map((response) => (response.accessorialLiterals.length ? response.accessorialLiterals[0] : null)));
  }

  createAccessorialLiteral(accessorial: AccessorialLiteral) {
    const request = new CreateAccessorialLiteralRqst();
    request.accessorialLiteral = accessorial;
    if (!request.accessorialLiteral.originSicCd) {
      request.accessorialLiteral.originSicCd = ' ';
    }
    if (!request.accessorialLiteral.destinationSicCd) {
      request.accessorialLiteral.destinationSicCd = ' ';
    }
    if (!request.accessorialLiteral.district) {
      request.accessorialLiteral.district = ' ';
    }
    if (!request.accessorialLiteral.region) {
      request.accessorialLiteral.region = ' ';
    }
    return this.pricingAgmtMgmtApiService
      .createAccessorialLiteral(request)
      .pipe(map((result) => result.accessorialLiteral));
  }

  redirectToCWY() {
    window.open(this.pricingAgmtMgmtPricingDocumentSearchAppUrl, "_blank");
  }

  public setDetailData(data) {
    this.detailData.next(data);
  }

  public listAccessorialLiteralHists(body) {
    const request = new ListAccessorialLiteralHistsRqst();
    request.accessorialLiteralHists = body;
    return this.pricingAgmtMgmtApiService.listAccessorialLiteralHists(request).pipe(
      map(result => {
        return result.accessorialLiteralHists;
      })
    );
  }

  public listAccessorialLiteralDistricts(): Observable<string[]> {
    return this.pricingAgmtMgmtApiService.listAccessorialLiteralDistricts().pipe(
      map(result => result.districts)
    );
  }

  public listAccessorialLiteralRegions(): Observable<string[]> {
    return this.pricingAgmtMgmtApiService.listAccessorialLiteralRegions().pipe(
      map(result => result.regions)
    );
  }

  public humanizeBooleanWithSpace = (params: any) => {
    switch (params.value) {
      case this.constantsService.SHORT_YES:
        return 'Yes';
      case 'N':
        return 'No';
      case 'P':
        return '';
      default:
        return params.value;
    }
  };

  public transformDate = (params: any) => {
    return params.value ? moment(params.value).format(this.constantsService.DATE_FORMAT_FOR_DISPLAY) : '';
  }
}

import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { GridApi } from 'ag-grid-community';

export interface AppliedFilterModel {
  field: string;
  columnHeader: string;
  filter: { [key: string]: any };
}

@Component({
  selector: 'app-grid-applied-filters',
  templateUrl: './grid-applied-filters.component.html',
  styleUrls: ['./grid-applied-filters.component.scss'],
})
export class GridAppliedFiltersComponent implements OnInit, OnChanges, OnDestroy {
  @Input() gridApi: GridApi;

  appliedFiltersList: AppliedFilterModel[] = [];

  constructor() {}

  ngOnInit() {
    this.updateAppliedFilters();
  }

  ngOnChanges() {
    this.updateAppliedFilters();
  }

  ngOnDestroy(): void {
    //this.gridApi.destroy();
  }
  /**
   * removes a single filter from the list and updates the filterModel
   * @param index
   */
  removeFilter(filter: AppliedFilterModel): void {
    const filterModel = this.gridApi?.getFilterModel();
    delete filterModel[filter.field];
    this.gridApi.setFilterModel(filterModel);
  }

  /**
   * refresh filterModel into an AppliedFilterModel array
   */
  updateAppliedFilters(): void {
    const filterModel = this.gridApi?.getFilterModel();
    this.appliedFiltersList = [];

    if (filterModel) {
      Object.keys(filterModel).forEach((key) => {
        const filter = filterModel[key];
        const colDef = this.gridApi.getColumnDef(key);
        this.appliedFiltersList.push({
          field: colDef.field,
          columnHeader: colDef.headerName || colDef.field,
          filter: filter,
        });
      });
    }
  }

  removeAllFilters(): void {
    this.gridApi.setFilterModel(null);
  }
}
